<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="">
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">账号</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="username"
                    type="text"
                    maxlength="11"
                    placeholder="请输入邮箱/手机号"
                    value=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="mcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                    value=""
                  />
                </p>
              </div>
              <div class="weui-cell__ft">
                <button class="weui-vcode-btn public-btn-vcode btn-verify-forget" data-field="">
                  获取验证码
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">密码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="password"
                    type="password"
                    maxlength="20"
                    placeholder="请输入6~20位密码"
                    value=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="public-areas public-area-user-list public-login-form margin-bottom-10 margin-top-10"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">确认密码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="re_password"
                    type="password"
                    maxlength="20"
                    placeholder="再次输入密码"
                    value=""
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a href="javascript:;" class="weui-btn weui-btn_primary public-btn-resetPassword"
                  >重置密码</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "forget",
};
</script>
